<template>
  <base-section id="how-security">
    <base-section-heading
      color="primary lighten-2"
      :title="$i18n.t('title')"
    />
    <v-container>
      <v-row>
        <v-col>
          <v-timeline>
            <v-timeline-item
              v-for="(item, i) of timeline"
              :key="i"
              large
            >
              <template v-slot:icon>
                <base-avatar
                  color="primary"
                  :icon="item.icon"
                  :size="72"
                  outlined
                  dark
                />
              </template>
              <div class="ma-4">
                <base-title
                  :title="$t(`timeline.t${i}.title`)"
                  class="text-uppercase"
                  space="1"
                  :align="i % 2 === 1 ? 'end' : null"
                />
                <base-divider
                  :align="i % 2 === 1 ? 'right' : 'left'"
                  color="primary"
                />
                <div :class="i % 2 === 1 ? 'd-flex justify-end' : null">
                  <base-body
                    :text="$t(`timeline.t${i}.subtitle`)"
                    space="0"
                  />
                </div>

              </div>
              <template
                v-slot:opposite
                class="d-flex"
              >
                <p class="primary--text text-h6 mx-4 my-0">{{ $t(`timeline.t${i}.date`) }}</p>
              </template>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col
          v-for="(number, i) of numbers"
          :key="i"
          class="d-flex justify-start align-center flex-column"
        >
          <v-lazy>
            <counter
              key="counter"
              :number="number"
            />
          </v-lazy>
          <p
            key="text"
            style="max-width: 200px;"
            class="text-center grey--text text--darken-1 mt-2"
          >
            {{ $t(`stats.t${i}.text`) }}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>
<script>
  import counter from '@/components/lib/counter.vue'

  export default {
    components: { counter },
    name: 'InfograficaAccessi',
    data () {
      return {
        poller: null,
        timeline: [
          {
            icon: 'fas fa-lightbulb',
          },
          {
            icon: 'fas fa-qrcode',
          },
          {
            icon: 'fas fa-eye',
          },
        ],
        numbers: [20_000, 9633, 8, 20, 40_000, 10],
      }
    },
  }
</script>
<i18n>
{
  "en": {
    "title": "Some numbers from 2020 to today"
  },
  "it": {
    "title": "Un po' di numeri dal 2020 ad oggi",
    "timeline": {
      "t0": {
        "title": "Nascita del progetto",
        "subtitle": "Prende forma l'idea del progetto SanyBeach",
        "date": "1 Giugno 2020"
      },
      "t1": {
        "title": "Primo codice QR scansionato",
        "subtitle": "Viene scansionato il primo codice QR affisso su un ombrellone",
        "date": "29 Giugno 2020"
      },
      "t2": {
        "title": "370 accessi in un giorno",
        "subtitle": "Viene raggiunto il picco di 370 utenti che visualizzano il sito in una sola giornata",
        "date": "19 Agosto 2020"
      }
    },
    "stats": {
      "t0": {
        "text": "QR CODE affissi"
      },
      "t1": {
        "text": "Utenti che hanno scansionato il codice QR"
      },
      "t2": {
        "text": "Stabilimenti che hanno aderito subito"
      },
      "t3": {
        "text": "Lotti sanificati"
      },
      "t4": {
        "text": "Sdrai sanificati"
      },
      "t5": {
        "text": "Campionamenti effettuati per verificare la correttezza della sanificazione"
      }
    }
  }
}
</i18n>
