<template>
  <div class="counter">{{ readable }}</div>
</template>
<script>
  export default {
    props: {
      number: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        time: 4000,
        interval: 50,
        tick: 1,
        value: 0,
        readable: 0,
      }
    },
    created () {
      this.tick = this.number * this.interval / this.time
      this.interval = setInterval(() => {
        if (this.value < this.number) {
          this.value += this.tick
          this.readable = Math.floor(this.value)
        } else {
          clearInterval(this.interval)
        }
      }, this.interval)
    },
  }
</script>
<style scoped>
  .counter {
    border: .4rem solid #00bcd4;
    color: #00bcd4;
    font-size: 2rem;
    font-weight: 600;
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 400px) {
    .counter {
      font-size: 2rem;
      width: 8rem;
      height: 8rem;
    }
  }
</style>
